import { Text, Title } from "@clipboard-health/ui-react";
import { Link, type TypographyProps } from "@mui/material";
import { fromPairs } from "lodash";
// eslint-disable-next-line no-restricted-imports
import Markdown, { type MarkdownToJSX } from "markdown-to-jsx";
import { Fragment, type ReactElement } from "react";

interface MuiMarkdownProps {
  children: string;
  variant?: TypographyProps["variant"];
  color?: TypographyProps["color"];
}

const HEADING_COMPONENTS = ["h1", "h2", "h3", "h4", "h5", "h6"];
const HEADING_OVERRIDES: Record<string, MarkdownToJSX.Override> = fromPairs(
  HEADING_COMPONENTS.map((component) => {
    return [
      component,
      {
        component: Title,
        props: {
          gutterBottom: true,
          component,
        },
      },
    ];
  })
);

export function MuiMarkdown(props: MuiMarkdownProps): ReactElement {
  const { children, variant, color } = props;

  return (
    <Markdown
      options={{
        wrapper: Fragment,
        disableParsingRawHTML: true,
        overrides: {
          ...HEADING_OVERRIDES,
          p: {
            component: Text,
            props: {
              paragraph: true,
              variant: variant ?? "h3",
              color: color ?? "inherit",
            },
          },
          span: {
            /**
             * FIXME: Do not use `Text` or `p` elements for span
             */
            component: Text,
            props: {
              paragraph: false,
              variant: variant ?? "h3",
              color: color ?? "inherit",
            },
          },
          a: {
            component: Link,
            props: {
              rel: "noopener noreferrer",
              target: "_blank",
            },
          },
        },
      }}
    >
      {children}
    </Markdown>
  );
}
