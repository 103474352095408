import { SnackbarProvider } from "notistack";
import { type ReactNode } from "react";

/**
 * FIXME - find a way to use notistack with css-in-js or styled components.
 * Tested `styled` function, and `sx` props
 */
// eslint-disable-next-line no-restricted-imports,
import classes from "./Snackbar.module.scss";
import { SnackbarToast } from "./Toast";

interface MuiSnackBarProviderProps {
  children: ReactNode;
}
export function MuiSnackBarProvider(props: MuiSnackBarProviderProps) {
  const { children } = props;
  return (
    <SnackbarProvider
      preventDuplicate
      domRoot={document.body}
      classes={{
        /**
         * FIXME - find a way to use notistack with css-in-js or styled components.
         * Tested `styled` function, and `sx` props
         */
        containerAnchorOriginTopCenter: classes.SnackbarContainerRoot,
      }}
      Components={{
        default: SnackbarToast,
        error: SnackbarToast,
        info: SnackbarToast,
        warning: SnackbarToast,
        success: SnackbarToast,
      }}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      autoHideDuration={5000}
      maxSnack={2}
    >
      {children}
    </SnackbarProvider>
  );
}
